const moment = require('moment');
const { getSpreadedInvoiceItems } = require('../services/invoiceCalculationService/helpers');
const {
  CALCULATED_POSITION_TYPES,
} = require('../services/invoiceCalculationService/types/calculatedPosition.types');
const { roundTwoDigitsCommercial } = require('../services/invoiceCalculationService');
const { PRODUCT_TYPES } = require('./productTypes');

const INVOICE_STATUSES = Object.freeze({
  DRAFT: 'draft',
  PREVIEW: 'preview',
  INTERNAL: 'internal',
  OPEN: 'open',
  PAID: 'paid',
  CANCELLING: 'cancelling',
  PAID_CREDIT: 'paidCredit',
  COST_LISTING: 'costListing',
});

const INVOICE_FINALE_STATUSES = [
  INVOICE_STATUSES.OPEN,
  INVOICE_STATUSES.PAID,
  INVOICE_STATUSES.PAID_CREDIT,
];

const INVOICE_CAN_EDIT_STATUSES = [
  INVOICE_STATUSES.DRAFT,
  INVOICE_STATUSES.PREVIEW,
  INVOICE_STATUSES.INTERNAL,
];

const INVOICE_CAN_ADD_PAYMENT_STATUS = INVOICE_STATUSES.OPEN;

function getPdfTypeForStatus(status) {
  return [
    INVOICE_STATUSES.DRAFT,
    INVOICE_STATUSES.INTERNAL,
    INVOICE_STATUSES.PREVIEW,
    INVOICE_STATUSES.COST_LISTING,
  ].includes(status)
    ? status
    : 'invoice';
}

function isFinalStatusOrCostListing(status) {
  return [...INVOICE_FINALE_STATUSES, INVOICE_STATUSES.COST_LISTING].includes(status);
}

function isPaidInvoice(status) {
  return [INVOICE_STATUSES.PAID, INVOICE_STATUSES.PAID_CREDIT].includes(status);
}

function isOpenInvoice(status) {
  return status === INVOICE_STATUSES.OPEN;
}

function isPreviewInvoice(status) {
  return status === INVOICE_STATUSES.PREVIEW;
}

function isDraftInvoice(status) {
  return status === INVOICE_STATUSES.DRAFT;
}

function getChangeInvoiceStatus() {
  return [INVOICE_STATUSES.OPEN, INVOICE_STATUSES.PREVIEW];
}

function getDraftPrinterStatus() {
  return [INVOICE_STATUSES.DRAFT, INVOICE_STATUSES.INTERNAL];
}

function getInvoiceCanBeCreatedStatus() {
  return [INVOICE_STATUSES.DRAFT, INVOICE_STATUSES.PREVIEW];
}

function getInvoiceCanBeReopenedStatus() {
  return INVOICE_STATUSES.PAID;
}

function getInvoiceCanBeCancelledStatus() {
  return INVOICE_STATUSES.OPEN;
}

function getInvoiceCanBeRemovedStatus() {
  return [INVOICE_STATUSES.DRAFT, INVOICE_STATUSES.PREVIEW];
}

function getInvoiceCanBeUpdatedStatus() {
  return [
    INVOICE_STATUSES.DRAFT,
    INVOICE_STATUSES.PREVIEW,
    INVOICE_STATUSES.OPEN,
    INVOICE_STATUSES.COST_LISTING,
  ];
}

function isDraftPrintStatus(status) {
  return getDraftPrinterStatus().includes(status);
}

function isShouldChangeInventoryStatus(status) {
  return [INVOICE_STATUSES.OPEN, INVOICE_STATUSES.COST_LISTING].includes(status);
}

function getChangeStatusTrackingData(status) {
  if (status === INVOICE_STATUSES.OPEN) {
    return { event: 'Finalized invoice', fromDraft: false };
  }
  if ([INVOICE_STATUSES.DRAFT, INVOICE_STATUSES.PREVIEW].includes(status)) {
    return { event: 'Created preview', fromDraft: true };
  }
  return { event: `Changed invoice state to ${status}`, fromDraft: false };
}

function getInvoicePrintStatusByStatus(invoice, status) {
  return [INVOICE_STATUSES.OPEN, INVOICE_STATUSES.PAID, INVOICE_STATUSES.PAID_CREDIT].includes(
    invoice.status,
  ) && status === INVOICE_STATUSES.PREVIEW
    ? invoice.status
    : status;
}

function getPayedStatusForCreditOrNot(isCredit) {
  return isCredit ? INVOICE_STATUSES.PAID_CREDIT : INVOICE_STATUSES.PAID;
}

function shouldDraftPrinterBeUsed(status) {
  return [INVOICE_STATUSES.DRAFT, INVOICE_STATUSES.INTERNAL].includes(status);
}

function isPreviewNotChangedToOpenInvoice(statusBeforeUpdate, statusAfterUpdate) {
  return (
    statusBeforeUpdate === INVOICE_STATUSES.PREVIEW && statusAfterUpdate !== INVOICE_STATUSES.OPEN
  );
}

function getAccessForWorkersStatus() {
  return INVOICE_STATUSES.DRAFT;
}

function isAccessForWorkers(status) {
  return [INVOICE_STATUSES.DRAFT].includes(status);
}

function isAccessForAccountantStatus(status) {
  return [INVOICE_STATUSES.OPEN, INVOICE_STATUSES.PAID, INVOICE_STATUSES.PAID_CREDIT].includes(
    status,
  );
}

function isShouldRenderPrintPdfStatus(status) {
  return [
    INVOICE_STATUSES.PREVIEW,
    INVOICE_STATUSES.OPEN,
    INVOICE_STATUSES.PAID,
    INVOICE_STATUSES.PAID_CREDIT,
    INVOICE_STATUSES.COST_LISTING,
  ].includes(status);
}

function isShouldRenderInternalPdfStatus(status) {
  return status === INVOICE_STATUSES.INTERNAL;
}

function isShouldAddInvoiceNumberToExcelStatus(status) {
  return status !== INVOICE_STATUSES.COST_LISTING;
}

function isShouldPdfRenderPaymentQrCodeStatus(status) {
  return [INVOICE_STATUSES.OPEN].includes(status);
}

function isPDFGeneratedOnTheFlyStatus(status) {
  return [INVOICE_STATUSES.DRAFT, INVOICE_STATUSES.COST_LISTING].includes(status);
}

function canBeChangedToCostListing(status) {
  return [INVOICE_STATUSES.PREVIEW].includes(status);
}

function getItemAllowances(items = []) {
  return getSpreadedInvoiceItems(items).filter(
    (item) =>
      item.calculatedPositionType === CALCULATED_POSITION_TYPES.SUBTRACT ||
      (item.calculatedPositionType === CALCULATED_POSITION_TYPES.ROUND && item.unitPrice < 0) ||
      (item.type === PRODUCT_TYPES.DOWN_PAYMENT && item.unitPrice < 0),
  );
}

function getItemsWithoutAllowances(items) {
  return getSpreadedInvoiceItems(items).filter(
    (item) =>
      item.calculatedPositionType !== CALCULATED_POSITION_TYPES.SUBTRACT &&
      !(item.calculatedPositionType === CALCULATED_POSITION_TYPES.ROUND && item.unitPrice < 0) &&
      !(item.type === PRODUCT_TYPES.DOWN_PAYMENT && item.unitPrice < 0),
  );
}

function getDueDateDisplayString(dueDate) {
  if (dueDate) {
    return moment(dueDate).format('DD.MM.YYYY');
  }
  return '';
}

function getBenefitPeriodDisplayString(benefitPeriod, invoiceDate, defaultValue) {
  if (!benefitPeriod) return '';
  if (typeof benefitPeriod === 'string') return benefitPeriod;
  if (benefitPeriod.from === benefitPeriod.to && benefitPeriod.from === invoiceDate) {
    return defaultValue;
  }
  const from = benefitPeriod.from ? moment(benefitPeriod.from).format('DD.MM.YYYY') : '';
  const to = benefitPeriod.to ? moment(benefitPeriod.to).format('DD.MM.YYYY') : '';
  if (from === to) {
    return from;
  }
  return `${from} - ${to}`;
}

function buildBenefitPeriod(benefitPeriod, invoiceDate, draftExecutionDate) {
  if (benefitPeriod && typeof benefitPeriod === 'object') {
    return benefitPeriod;
  }
  return {
    from: invoiceDate || draftExecutionDate || moment.utc().startOf('day').toDate(),
    to: invoiceDate || draftExecutionDate || moment.utc().startOf('day').toDate(),
  };
}

function calculateDueDays(dueDaysFromInvoice, invoiceSettings) {
  let dueDays = dueDaysFromInvoice;
  if ([null, undefined].includes(dueDays)) {
    const { dueDateInterval, dueDateTimeFrame } = invoiceSettings;
    let multiplier = 1;
    if (dueDateInterval === 'weeks') multiplier = 7;
    if (dueDateInterval === 'months') multiplier = 30;
    dueDays = dueDateTimeFrame * multiplier;
  }
  return dueDays;
}

function calculateDueDate(invoiceDate, dueDaysFromInvoice, invoiceSettings) {
  if (!invoiceDate) {
    return null;
  }

  const dueDays = calculateDueDays(dueDaysFromInvoice, invoiceSettings);
  return moment(invoiceDate).utc().add(dueDays, 'days').startOf('day').toDate();
}

/**
 * Calculates the actual open amount of an invoice.
 * We currently do not reduce the openAmount inside the invoice object by the amount of credit notes.
 * !So do not use this function to calculate the openAmount for an invoice object!
 * @param {*} invoice
 * @returns the actual open amount of the invoice
 */
function calculateActualOpenInvoiceAmount(invoice) {
  const { gross, payments, paymentsWaitingForMypos, paymentCredits = [] } = invoice;
  const paymentsPending = [...(paymentsWaitingForMypos || [])].reduce(
    (sum, { amount }) => sum + amount,
    0,
  );
  const paymentsDone = [...payments].reduce((sum, { amount }) => sum + amount, 0);
  const paymentTotal = paymentsDone + paymentsPending;
  const creditTotal = paymentCredits.reduce((sum, { amount }) => sum + amount, 0) || 0;
  return roundTwoDigitsCommercial(gross - paymentTotal - creditTotal);
}

module.exports = {
  INVOICE_STATUSES,
  INVOICE_FINALE_STATUSES,
  INVOICE_CAN_EDIT_STATUSES,
  INVOICE_CAN_ADD_PAYMENT_STATUS,
  getChangeInvoiceStatus,
  getPdfTypeForStatus,
  getInvoiceCanBeCreatedStatus,
  getInvoiceCanBeReopenedStatus,
  getInvoiceCanBeCancelledStatus,
  getInvoiceCanBeRemovedStatus,
  getInvoiceCanBeUpdatedStatus,
  getInvoicePrintStatusByStatus,
  isFinalStatusOrCostListing,
  isPaidInvoice,
  isOpenInvoice,
  isPreviewInvoice,
  isDraftInvoice,
  getDraftPrinterStatus,
  isDraftPrintStatus,
  isPreviewNotChangedToOpenInvoice,
  getChangeStatusTrackingData,
  getPayedStatusForCreditOrNot,
  getAccessForWorkersStatus,
  isAccessForWorkers,
  isAccessForAccountantStatus,
  shouldDraftPrinterBeUsed,
  isShouldChangeInventoryStatus,
  isShouldRenderPrintPdfStatus,
  isShouldRenderInternalPdfStatus,
  isShouldAddInvoiceNumberToExcelStatus,
  isShouldPdfRenderPaymentQrCodeStatus,
  isPDFGeneratedOnTheFlyStatus,
  canBeChangedToCostListing,
  getItemAllowances,
  getItemsWithoutAllowances,
  getDueDateDisplayString,
  getBenefitPeriodDisplayString,
  buildBenefitPeriod,
  calculateDueDays,
  calculateDueDate,
  calculateActualOpenInvoiceAmount,
};

import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

import cx from 'classnames';
import polyglot, { getShortLocale } from 'services/localization';
import { getInitialRoute, hasPermission } from 'helpers/permissions';
import ClientsList from 'components/clients/clientsList/async';
import Client from 'components/clients/client/async';
import ClientVehiclesList from 'components/clients/client/components/vehiclesList';
import ClientInvoices from 'components/clientInvoices/async';
import ClientOffers from 'components/clientOffers/async';
import Vehicles from 'components/vehicles/vehiclesList/async';
import Vehicle from 'components/vehicles/vehicle/async';
import VehicleTires from 'components/vehicles/vehicle/components/vehicleTires';
import VehicleInvoices from 'components/vehicles/vehicle/components/vehicleInvoices';
import VehicleOffers from 'components/vehicles/vehicle/components/vehicleOffers';
import OffersManager from 'components/offers/list/async';
import CreateOffer from 'components/offers/create/async';
import OfferDetails from 'components/offers/details/async';
import InvoiceList from 'components/invoices/invoicesList/async';
import CreateInvoice from 'components/invoices/createInvoice/async';
import Invoice from 'components/invoices/invoice/async';
import CashbookManager from 'components/cashbook/async';
import Products from 'components/products/async';
import FirstSteps from 'components/firstSteps/async';
import EmailsManager from 'components/emails/async';
import Reports from 'components/reports/async';
import WelcomePage from 'components/welcomePage/async';
import Subscription from 'components/subscription/async';
import Dashboard from 'components/dashboard/async';
import Calendar from 'components/calendar/async';
import UserSettings from 'components/settings/userSettings/async';
import GarageSettings from 'components/settings/garageSettings/async';
import PrintSettings from 'components/settings/printSettings/async';
import CashSettings from 'components/settings/cashSettings/async';
import CashRegisterSettings from 'components/settings/cashRegisterSettings/async';
import InterfacesSettings from 'components/settings/interfacesSettings/async';
import { ACTIVE_STATUSES } from 'shared-library/src/definitions/subscription';
import Helmet from 'react-helmet';
import { isGarageLocked } from 'helpers/subscription';
import { hideSupportBeaconOnMobile } from 'services/beacon.service';
import RecycleBinList from 'components/recycleBin/async';
import SorryBanner from 'components/sorryBanner';
import localStorage from 'services/localStorage';
import { identifyUser, urlHasChanged } from 'services/tracking.service';
import Sdi from 'components/sdi/async';
import Banking from 'components/banking/async';
import { checkBetaFeatureActive, FEATURE_IDS } from 'shared-library/src/services/betaFeatureService';
import Header from './components/header';
import Sidebar from './components/sidebar';
import NewYearModal from './components/newYearModal';
import DownloadDisplay from './components/downloadDisplay';
import Banner from './components/banner';
import Breadcrumbs from './components/breadcrumbs';
import styles from './layout.styles.pcss';
import ProductHistory from '../products/productHistory/productHistory';
import FabNavigation from './components/fabNavigation';

class Layout extends PureComponent {
  // Add unused local Storage keys here
  // Or remove function(and call) if no longer required (01.12.2022)
  static cleanUpLocalStorage = () => {
    localStorage.removeItem('isVATIncluded');
  };

  static propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
      location: PropTypes.object.isRequired,
    }).isRequired,
    subscriptionData: PropTypes.shape({
      status: PropTypes.string,
    }),
    updateLastLogin: PropTypes.func.isRequired,
    garage: PropTypes.shape({
      country: PropTypes.string,
    }).isRequired,
    mobileViewOption: PropTypes.string.isRequired,
  };

  static defaultProps = {
    subscriptionData: {
      status: '',
    },
  };

  state = {
    isSidebarOpen: true,
    wasSidebarLastTouched: false,
    isNewYearLogin: false,
  };

  componentDidMount = () => {
    const { initialRoute, user, isShadowLogin } = window.config;
    const { roles, history } = this.props;

    Layout.cleanUpLocalStorage();

    if (user) {
      identifyUser(user._id);
    }

    const pathname = getInitialRoute(
      roles,
      history.location.pathname,
      initialRoute,
    );
    if (!this.shouldRedirectToSubscription() && history.location.pathname !== pathname) {
      history.replace(pathname);
    }

    this.setState({ isNewYearLogin: this.checkIsNewYearLogin() });

    if (!isShadowLogin) {
      const { updateLastLogin } = this.props;
      updateLastLogin(user._id);
    }
    hideSupportBeaconOnMobile();
  };

  shouldRedirectToSubscription = () => {
    const { subscriptionData, garage } = this.props;
    return (window.config.stripe.enabled && (!ACTIVE_STATUSES.includes(subscriptionData.status)))
      || isGarageLocked(garage);
  };

  checkIsNewYearLogin = () => {
    const { roles } = this.props;
    const { user, isShadowLogin } = window.config;
    const dateNow = new Date();

    return roles.includes('admin')
          && (!user.lastLogin || new Date(user.lastLogin).getFullYear() < dateNow.getFullYear())
          && dateNow.getMonth() === 0
          && !isShadowLogin;
  };

  toggleSidebar = () => {
    const { isSidebarOpen } = this.state;
    this.setState({ isSidebarOpen: !isSidebarOpen });
  };

  templateSelectionActions = [
    {
      title: polyglot.t('printing.templateSelection.next'),
      primary: true,
      action: this.selectTemplate,
    },
  ];

  renderNewYearModal() {
    return (
      <NewYearModal
        isOpen={this.state.isNewYearLogin}
        onClose={() => { this.setState({ isNewYearLogin: false }); }}
      />
    );
  }

  render() {
    const { history, garage, mobileViewOption } = this.props;
    const { user } = window.config;
    const { isSidebarOpen } = this.state;
    const location = history.location.pathname;

    const locationChanged = localStorage.getItem('segment') !== location;

    if (locationChanged) {
      urlHasChanged(location);
    }

    if (this.shouldRedirectToSubscription()) {
      if (!history.location.pathname.match(/^\/subscription[/]{0,1}[\w]*/)) {
        return <Redirect to="/subscription" />;
      }
    }
    const lang = getShortLocale();

    return (
      <div className={styles.layout} lang={lang}>
        <Helmet>
          <meta name="viewport" content={mobileViewOption && mobileViewOption !== 'default' ? 'width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no' : ''}/>
        </Helmet>
        <div
          className={cx(styles.layoutGrid, isSidebarOpen ? '' : styles.layoutGridMin)}
          onTouchStart={() => this.setState({ wasSidebarLastTouched: false })}
        >
          <Header
            history={history}
            pathname={history.location.pathname}
          />
          <div className={styles.layoutExtraContent}>
            <Banner history={history}/>
            <SorryBanner />
          </div>
          <Sidebar
            isSidebarOpen={isSidebarOpen}
            pathname={history.location.pathname}
            sidebarWasTouched={() => this.setState({ wasSidebarLastTouched: true })}
            wasSidebarLastTouched={this.state.wasSidebarLastTouched}
            toggleSidebar={this.toggleSidebar}
          />

          <div
            id={'layout'}
            className={styles.layoutContent}
            onTouchStart={() => this.setState({ wasSidebarLastTouched: false })}
          >
            {this.state.isNewYearLogin && this.renderNewYearModal()}
            <Breadcrumbs pathname={history.location.pathname} />

            <DownloadDisplay />

            <Switch>
              {/*
                This code work fine, but it still has the propTypes warning in the console.
                This will be fixed in react-router@4.4.0
                https://github.com/ReactTraining/react-router/issues/5866
              */}
              <Route
                exact
                path={['/welcome']}
                render={(props) => (
                  <Fragment>
                    <Dashboard {...props} />
                    <WelcomePage {...props} />
                  </Fragment>
                )}
              />
              <Route
                exact
                path={'/clients/:tab?'}
                render={(props) => (
                  <Fragment>
                    <ClientsList {...props} />
                  </Fragment>
                )}
              />
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/firstSteps" component={FirstSteps} />
              <Route exact path="/calendar" component={Calendar} />
              <Route exact path="/calendar/new" component={Calendar} />
              <Route exact path="/calendar/events/:eventId/edit" component={Calendar} />
              <Route exact path="/calendar/events/:eventId/delete" component={Calendar} />
              <Route exact path="/clients/:tab?" component={ClientsList} />
              <Route
                exact
                path="/clients/all/create-with-document"
                render={(props) => (<ClientsList {...props} />)}
              />
              <Route exact path="/clients/:tab/:id" component={Client} />
              <Route exact path="/clients/:tab/:id/vehicles" component={ClientVehiclesList} />
              <Route exact path="/clients/:tab/:id/invoices" component={ClientInvoices} />
              <Route
                exact
                path="/clients/:tab/:id/products"
                render={(props) => (
                  <ProductHistory type="client" {...props} />
                )}
              />
              <Route exact path="/clients/:tab/:id/create-with-document" render={(props) => (<Client {...props} />)} />
              <Route exact path="/clients/:tab/:id/offers" component={ClientOffers} />
              <Route exact path="/vehicles/:tab?" component={Vehicles} />
              <Route exact path="/vehicles/:tab/:id" component={Vehicle} />
              <Route exact path="/vehicles/:tab/:id/tires" component={VehicleTires} />
              <Route exact path="/vehicles/:tab/:id/invoices" component={VehicleInvoices} />
              <Route
                exact
                path="/vehicles/:tab/:id/products"
                render={(props) => (
                  <ProductHistory type="vehicle" {...props} />
                )}
              />
              <Route exact path="/vehicles/:tab/:id/offers" component={VehicleOffers} />
              <Route exact path="/offers" component={OffersManager} />
              <Route exact path="/offers/new" component={CreateOffer} />
              <Route exact path="/offers/:id" component={OfferDetails} />
              <Route exact path="/offers/:id/emailState/:pdfType" component={OfferDetails} />
              <Route exact path="/invoices" component={InvoiceList} />
              <Route exact path="/invoices/:status" component={InvoiceList} />
              <Route exact path="/invoices/:status/new" render={CreateInvoice} />
              <Route exact path="/invoices/:status/:id" component={Invoice} />
              <Route exact path="/invoices/:status/:id/emailState/:pdfType" component={Invoice} />
              <Route exact path="/cashbook" component={CashbookManager} />
              <Route exact path="/products/:tab?" component={Products} />
              <Route
                exact
                path="/products/:id/history"
                render={(props) => (
                  <ProductHistory type="product" {...props} />
                )}
              />
              <Route
                exact
                path="/reports/products/:id/history"
                render={(props) => (
                  <ProductHistory type="product" {...props} />
                )}
              />
              <Route exact path="/emails" component={EmailsManager} />
              <Route exact path="/bin" component={RecycleBinList} />
              <Route exact path="/bin/:status" component={RecycleBinList} />
              <Route exact path="/reports" component={Reports} />
              <Route exact path="/reports/:type" component={Reports} />
              <Route exact path="/garageSettings" component={GarageSettings} />
              <Route exact path="/garageSettings/:section" component={GarageSettings} />
              <Route exact path="/userSettings" component={UserSettings} />
              <Route exact path="/userSettings/:section" component={UserSettings} />
              <Route exact path="/printSettings" component={PrintSettings} />
              <Route exact path="/printSettings/:section" component={PrintSettings} />
              <Route exact path="/cashSettings" component={CashSettings} />
              <Route exact path="/cashSettings/:section" component={CashSettings} />
              <Route exact path="/cashRegisterSettings" component={CashRegisterSettings} />
              <Route exact path="/cashRegisterSettings/:section" component={CashRegisterSettings} />
              <Route exact path="/interfacesSettings" component={InterfacesSettings} />
              <Route exact path="/interfacesSettings/:section" component={InterfacesSettings} />
              {hasPermission(user, garage, 'fetchSdi') && (
                <Route path="/sdi" component={Sdi} />
              )}
              {hasPermission(user, garage, 'fetchBanking') && checkBetaFeatureActive(window.config.user, FEATURE_IDS.betaFeatureFinAPI) && (
                <Route path="/banking" component={Banking} />
              )}
              {window.config.stripe.enabled && (
                <>
                  <Route exact path="/subscription" component={Subscription} />
                  <Route exact path="/subscription/:tab" component={Subscription} />
                </>
              )}
            </Switch>
            {/* <IdleManager>
              <SnowScreen />
            </IdleManager> */}
          </div>
          {/* <BottomNavigation
            pathname={history.location.pathname}
          /> */}
          <FabNavigation onBurgerClicked={this.toggleSidebar} />
        </div>
      </div>
    );
  }
}

export default Layout;

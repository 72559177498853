import { connect } from 'react-redux';

import withWindowSize from 'components/higherOrderComponents/withWindowSize';
import * as fromClients from 'resources/clients/clients.selectors';
import {
  fetchClients,
  resetClientsState,
  changeClientsFilter,
} from 'resources/clients/clients.actions';
import SelectCustomer from './selectCustomer';

export default withWindowSize(connect(
  (state) => ({
    clients: fromClients.getClients(state),
    totalAmount: fromClients.getTotalAmount(state),
  }),
  {
    fetchClients,
    resetClientsState,
    changeClientsFilter,
  },
)(SelectCustomer));

import * as fromUser from 'resources/user/user.selectors';
import * as fromGarage from 'resources/garage/garage.selectors';
import * as api from './garage.api';

export const UPDATE_GARAGE = 'updateGarage';
export const UPDATE_GARAGE_SOCKET = 'updateGarageSocket';
export const UPDATE_SUPPLIERS = 'updateSuppliers';
export const FETCH_GARAGE = 'fetchGarage';
export const UPDATE_NUMBER = 'updateNumber';
export const UPDATE_STRIPE_OBJECT = 'updateStripeObject';

export function fetchGarage() {
  return (dispatch, getState) => {
    const state = getState();
    const garageId = fromUser.getCurrentGarageId(state);

    return api.fetchGarage(garageId).then((payload) => dispatch({ type: FETCH_GARAGE, payload }));
  };
}

export const updateGarage = (data) => (dispatch, getState) => {
  const state = getState();
  const garageId = fromUser.getCurrentGarageId(state);

  const newGarage = { ...fromGarage.getGarage(state), ...data };
  return api.updateGarage(garageId, newGarage).then((payload) => {
    return dispatch({ type: UPDATE_GARAGE, payload });
  });
};

export function updateTaxRates(data) {
  return (dispatch, getState) => {
    const state = getState();
    const garageId = fromUser.getCurrentGarageId(state);

    return api
      .updateTaxRates(garageId, data)
      .then((payload) => dispatch({ type: UPDATE_GARAGE, payload }));
  };
}

export const updateInvoiceSettings = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api
    .updateInvoiceSettings(garageId, data)
    .then((payload) => dispatch({ type: UPDATE_GARAGE, payload }));
};

export const updateInvoiceDefaultDueDays = (dueDays) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api
    .updateInvoiceDefaultDueDays(garageId, { dueDays })
    .then((payload) => dispatch({ type: UPDATE_GARAGE, payload }));
};

export function updateEmailSettings(data) {
  return (dispatch, getState) => {
    const garageId = fromUser.getCurrentGarageId(getState());

    return api
      .updateEmailSettings(garageId, data)
      .then((payload) => dispatch({ type: UPDATE_GARAGE, payload }));
  };
}

export function updateNumber(name, value) {
  return (dispatch, getState) => {
    const garageId = fromUser.getCurrentGarageId(getState());

    const data = { [name]: value };
    return api
      .updateNumber(garageId, { sequenceName: name, sequenceValue: value })
      .then((payload) => dispatch({ type: UPDATE_NUMBER, data }));
  };
}

export function uploadImage(data) {
  return (dispatch, getState) => {
    const garageId = fromUser.getCurrentGarageId(getState());

    return api
      .uploadImage(garageId, data)
      .then((payload) => dispatch({ type: UPDATE_GARAGE, payload }));
  };
}

export function fetchImage(data) {
  return (dispatch, getState) => {
    const garageId = fromUser.getCurrentGarageId(getState());

    return api.fetchImage(garageId, data);
  };
}

export function renameImage(id, name) {
  return (dispatch, getState) => {
    const garageId = fromUser.getCurrentGarageId(getState());

    return api
      .renameImage(garageId, id, { name })
      .then((payload) => dispatch({ type: UPDATE_GARAGE, payload }));
  };
}

export function removeImages(ids) {
  return (dispatch, getState) => {
    const garageId = fromUser.getCurrentGarageId(getState());

    return api
      .removeImages(garageId, { fileIds: ids })
      .then((payload) => dispatch({ type: UPDATE_GARAGE, payload }));
  };
}

export const deleteGarageData = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.deleteGarageData(garageId, data);
};

export const getGarageDataCount = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getGarageDataCount(garageId);
};

export const stripeCheckout = (data) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.stripeCheckout(garageId, data);
};

export const getPaymentMethodData = (paymentMethodId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getPaymentMethodData(garageId, paymentMethodId);
};

export const getSetupIntent = (paymentMethodId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getSetupIntent(garageId, paymentMethodId);
};

export const getNextFullInvoicePreview
= (customerId, data, subscriptionId = null, interval = 'monthly') => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getNextFullInvoicePreview(garageId, {
    subscriptionId,
    customerId,
    interval,
    ...data,
  });
};

export const getNextInvoiceForUpdates
= (customerId, data, subscriptionId = null, interval = 'monthly') => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getNextInvoiceForUpdates(garageId, {
    subscriptionId,
    customerId,
    interval,
    ...data,
  });
};

export const getNextInvoicePreview
= (customerId, subscriptionId = null) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getNextInvoicePreview(garageId, {
    subscriptionId,
    customerId,
  });
};

export const getOpenInvoices = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getOpenInvoices(garageId);
};

export const getPriceList = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getPriceList(garageId);
};

export const getInvoicesForCustomer = (customerId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getInvoicesForCustomer(garageId, customerId);
};

export const cancelSubscription = (subscriptionId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.cancelSubscription(garageId, subscriptionId);
};

export const getCustomerWithPaymentData = (customerId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getCustomerWithPaymentData(garageId, customerId);
};

export const setDefaultPaymentMethod = (customerId, paymentMethodId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.setDefaultPaymentMethod(garageId, { customerId, paymentMethodId })
    .then((payload) => dispatch({ type: UPDATE_STRIPE_OBJECT, payload }));
};

export const transferOwnershipToUser = (userId) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.transferOwnershipToUser(garageId, { userId })
    .then((payload) => dispatch({ type: UPDATE_GARAGE, payload }));
};

export const updateCredentialsForSupplier = (supplier) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.updateCredentialsForSupplier(garageId, { supplier })
    .then((payload) => dispatch({ type: UPDATE_SUPPLIERS, payload }));
};

export const getCredentialsForSupplier = (supplier) => (dispatch, getState) => {
  return fromGarage.getCredentialsForSupplier(supplier, getState());
};

export const deleteCredentialsForSupplier = (supplierName) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.deleteCredentialsForSupplier(garageId, { supplierName })
    .then((payload) => dispatch({ type: UPDATE_SUPPLIERS, payload }));
};

export const setupFinanzOnlineAccount = (params) => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.setupFinanzOnlineAccount(garageId, params)
    .then(({ garage }) => dispatch({ type: UPDATE_GARAGE, payload: garage }));
};

export const getLatestReceiptOrCashbookEntryDate = () => (dispatch, getState) => {
  const garageId = fromUser.getCurrentGarageId(getState());
  return api.getLatestReceiptOrCashbookEntryDate(garageId).then((payload) => { return payload; });
};

import BackgroundBlob from 'components/access/components/backgroundBlob';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import polyglot, { getShortLocale } from 'services/localization';
import { Player } from '@lottiefiles/react-lottie-player';
import schraubi from 'assets/lottieFiles/Schraubi.json';

import styles from './maintenance.styles.pcss';

class Maintenance extends Component {
  componentDidMount() {
    setTimeout(() => window.location.reload(false), 10 * 60000); // Refresh every 10 minutes
  }

  render() {
    const lang = getShortLocale();
    return (
      <>
        <Helmet>
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no" />
        </Helmet>
        <div className={styles.maintenance} lang={lang}>
          <BackgroundBlob />
          <div className={styles.maintenanceContainer}>
            <Player
              autoplay
              loop
              src={schraubi}
              style={{ width: '140px' }}
            />
            <div className={styles.maintenanceContainerHeading}>
              {polyglot.t('maintenance.containerHeading')}
            </div>
            <div className={styles.maintenanceContainerText}>
              {polyglot.t('maintenance.containerText')}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Maintenance;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import withWindowSize from 'components/higherOrderComponents/withWindowSize';
import cx from 'classnames';
import SearchInputModern from '../searchInputModern';
import styles from './genericTableModal.styles.pcss';
import TabNavigationModern from '../tabNavigationModern';

class GenericTableModal extends Component {
  static propTypes = {
    tableData: PropTypes.array,
    onSearchChange: PropTypes.func,
    searchText: PropTypes.string,
    searchInputRef: PropTypes.object,
    isMobileSize: PropTypes.bool.isRequired,
    footerContent: PropTypes.object,
    allEmptyState: PropTypes.object,
    allNotFoundContent: PropTypes.object,
    selectedTab: PropTypes.string,
    onChangeTab: PropTypes.func,
    listInfo: PropTypes.string,
  };

  static defaultProps = {
    tableData: [],
    onSearchChange: () => null,
    searchText: '',
    searchInputRef: null,
    footerContent: null,
    allEmptyState: null,
    allNotFoundContent: null,
    selectedTab: '',
    onChangeTab: () => null,
    listInfo: '',
  };

  listContainerRef = React.createRef();

  renderListHeader(list) {
    return (
      <div className={styles.header}>
        <div className={styles.headerIcon}>
          {list.icon}
        </div>
        <span className={styles.headerText}>
          {list.title}
        </span>
        <div className={styles.selectButtonContainer}>
          {list.addActions || null}
        </div>
      </div>
    );
  }

  renderLists() {
    const { tableData } = this.props;
    return tableData.map((list, index) => (
      <div key={index} className={styles.list}>
        <div>
          {this.renderListHeader(list)}
        </div>
        {list.renderList}
      </div>
    ));
  }

  renderMobileLists() {
    const {
      tableData,
      selectedTab,
      onChangeTab,
    } = this.props;
    const tabs = tableData.map((list) => ({
      title: list.title,
      icon: list.icon,
      key: list.title,
    }));
    return (
      <>
        {selectedTab && (
          <TabNavigationModern
            tabs={tabs}
            selectedTab={selectedTab}
            onChangeTab={onChangeTab}
          />
        )}
        <div className={styles.addActionsMobile}>
          {tableData.find((list) => list.title === selectedTab)?.addActions
          || tableData[0].addActions}
        </div>
        {tableData.find((list) => list.title === selectedTab)?.renderList
        || tableData[0].renderList}
      </>
    );
  }

  render() {
    const {
      onSearchChange,
      searchText,
      searchInputRef,
      footerContent,
      allEmptyState,
      allNotFoundContent,
      isMobileSize,
      listInfo,
    } = this.props;
    const listHeight = (this.listContainerRef?.current?.clientHeight || 0) - 1;
    return (
      <div>
        <SearchInputModern
          id="searchInputGenericTableModal"
          onChange={onSearchChange}
          placeholder={polyglot.t('actions.searchPlaceholder')}
          value={searchText}
          dataTest="searchInputGenericTableModal"
          showAmount={false}
          className={cx(
            styles.genericTableSearch,
            { [styles.genericTableSearchMobile]: isMobileSize },
          )}
          ref={searchInputRef}
        />

        {isMobileSize ? this.renderMobileLists()
          : <>
            {allEmptyState && <div
              style={{ height: listHeight }}
            >
              {allEmptyState}
            </div>}
            {allNotFoundContent && <div
              style={{ height: listHeight }}
            >
              {allNotFoundContent}
            </div>}
            <div className={cx(
              { [styles.listGridHidden]: allEmptyState || allNotFoundContent },
            )}>
              {listInfo && (
              <div className={styles.info}>
                {listInfo}
              </div>
              )}
              <div
            ref={this.listContainerRef}
            className={cx(styles.listGrid)}>
                {this.renderLists()}
              </div>
            </div>
          </>}

        {footerContent}
      </div>
    );
  }
}

export default withWindowSize(GenericTableModal);

/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
const { roundNumberToNearestMultipleOf } = require('../numberHelperService');
const {
  calculateTotals,
  getNet,
  getGross,
  roundDigits,
  getTaxFromGross,
  getTaxFromNet,
} = require('./calculateTotals.service');
const {
  CALCULATED_POSITION_TYPES,
  SUB_ADD_TYPES,
  SUB_ADD_ON,
} = require('./types/calculatedPosition.types');

function findTaxRateFromTotals(totals, vatRate) {
  for (const taxSum of totals.taxSums) {
    if (Number(taxSum.rate) === vatRate) {
      return taxSum.amount;
    }
  }
  return null;
}

function getGrossWith4Digits(totals, vatRate) {
  let res = 0;
  for (const taxSum of totals.taxSums) {
    const roundingDigit = Number(taxSum.rate) === vatRate ? 4 : 2;
    res += getGross(taxSum.amount, Number(taxSum.rate), roundingDigit);
  }
  return res;
}

function calculateRoundingPosition(calculatedPosition, items, isVatIncluded) {
  const result = { netPrice: 0, taxes: 0 };
  const sums = calculateTotals(items, isVatIncluded);
  const taxSum = findTaxRateFromTotals(sums, calculatedPosition.vat.rate);
  let itemsGross = sums.gross;
  const roundedTotal = roundNumberToNearestMultipleOf(
    itemsGross,
    Number(calculatedPosition.calculatedPositionOptions.round.amount),
    calculatedPosition.calculatedPositionOptions.round.type,
  );
  let grossBalance = roundedTotal - itemsGross;

  if (isVatIncluded) {
    result.netPrice = roundDigits(grossBalance, 2);
    result.taxes = roundDigits(
      grossBalance - getNet(grossBalance, calculatedPosition.vat.rate, 2),
      2,
    );
  } else if (taxSum) {
    itemsGross = getGrossWith4Digits(sums, calculatedPosition.vat.rate);
    grossBalance = roundedTotal - itemsGross;
    result.netPrice = getNet(grossBalance, calculatedPosition.vat.rate, 4);
    result.taxes = roundDigits(grossBalance - result.netPrice, 4);
  } else {
    result.netPrice = getNet(grossBalance, calculatedPosition.vat.rate, 2);
    result.taxes = roundDigits(grossBalance - result.netPrice, 2);
  }
  return result;
}

function evaluateCalcPosition(calculatedPosition, items, isVatIncluded) {
  if (calculatedPosition.isInactive) {
    return {
      positionPrice: 0,
      price: 0,
      unitPrice: 0,
      tax: 0,
    };
  }
  let sign;
  switch (calculatedPosition.calculatedPositionType) {
    case CALCULATED_POSITION_TYPES.ADD:
      sign = '+';
      break;
    case CALCULATED_POSITION_TYPES.SUBTRACT:
      sign = '-';
      break;
    default:
      sign = '';
      break;
  }

  let price = 0;
  let taxes = 0;
  if (
    calculatedPosition.calculatedPositionType === CALCULATED_POSITION_TYPES.ADD ||
    calculatedPosition.calculatedPositionType === CALCULATED_POSITION_TYPES.SUBTRACT
  ) {
    // Calculate add and sub positions
    let itemsToUse = items;
    let groupsToUse = items;
    if (calculatedPosition.calculatedPositionOptions.subAndAdd.on !== SUB_ADD_ON.ALL) {
      itemsToUse = items.filter(
        (item) => item.type === calculatedPosition.calculatedPositionOptions.subAndAdd.on,
      );
      groupsToUse = items.map((item) => {
        if (item.type === 'group') {
          return item.items.filter(
            (groupItem) =>
              groupItem.type === calculatedPosition.calculatedPositionOptions.subAndAdd.on,
          );
        }
      });
      groupsToUse = groupsToUse.filter((group) => group && group.length > 0);
      for (let i = 0; i < groupsToUse.length; i += 1) {
        itemsToUse = [...itemsToUse, ...groupsToUse[i]];
      }
    }
    let total = 0;
    let uneditedTotal = 0;
    let multiplicator = 1;
    let changePerEntry = 0;
    let changeOnResult = 0;

    if (calculatedPosition.calculatedPositionOptions.subAndAdd.type === SUB_ADD_TYPES.PERCENT) {
      // Add and Sub in percent (tempted to use eval here..)
      if (sign === '+')
        multiplicator = 1 + calculatedPosition.calculatedPositionOptions.subAndAdd.amount / 100;
      if (sign === '-')
        multiplicator = 1 - calculatedPosition.calculatedPositionOptions.subAndAdd.amount / 100;
    } else if (
      calculatedPosition.calculatedPositionOptions.subAndAdd.type === SUB_ADD_TYPES.TOTAL
    ) {
      // Add and sub in €
      if (calculatedPosition.calculatedPositionOptions.subAndAdd.on === SUB_ADD_ON.ALL) {
        // On entire invoice
        changeOnResult = isVatIncluded
          ? Number(calculatedPosition.calculatedPositionOptions.subAndAdd.amount)
          : getNet(
              Number(calculatedPosition.calculatedPositionOptions.subAndAdd.amount),
              calculatedPosition.vat.rate,
            );
        if (sign === '-') changeOnResult *= -1;
      } else {
        // Per position type in invoice
        changePerEntry = isVatIncluded
          ? Number(calculatedPosition.calculatedPositionOptions.subAndAdd.amount)
          : getNet(
              Number(calculatedPosition.calculatedPositionOptions.subAndAdd.amount),
              calculatedPosition.vat.rate,
            );
        if (sign === '-') changePerEntry *= -1;
      }
    }
    itemsToUse.forEach((item) => {
      if (item.type === 'group') {
        item.items.map((groupItem) => {
          total += groupItem.price * groupItem.quantity * multiplicator + changePerEntry;
          uneditedTotal += groupItem.price * groupItem.quantity;
        });
      } else {
        total += item.price * item.quantity * multiplicator + changePerEntry;
        uneditedTotal += item.price * item.quantity;
      }
    });
    total += changeOnResult;
    price = roundDigits(total - uneditedTotal, 2);
    taxes = isVatIncluded
      ? roundDigits(getTaxFromGross(price, calculatedPosition.vat.rate), 2)
      : roundDigits(getTaxFromNet(price, calculatedPosition.vat.rate), 2);
  } else if (calculatedPosition.calculatedPositionType === CALCULATED_POSITION_TYPES.ROUND) {
    const res = calculateRoundingPosition(calculatedPosition, items, isVatIncluded);
    price = res.netPrice;
    taxes = res.taxes;
  }
  return {
    positionPrice: price,
    price,
    unitPrice: price,
    tax: taxes,
  };
}

module.exports = {
  evaluateCalcPosition,
};

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import polyglot from 'services/localization';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { MdOpenInNew as OpenIcon } from 'react-icons/md';
import { FontAwesomeIcon } from 'fontawesome/react-fontawesome';
import {
  faUser, faTimes, faUsers, faPlus,
  faPen,
  faXmark,
} from 'fontawesome/pro-solid-svg-icons';

import ButtonModern from 'components/common/buttonModern';
import GenericTableModal from 'components/common/genericTableModal';
import NoEntitiesModern from 'components/common/noEntitiesModern';
import Icon from 'components/common/icon/Icon';
import LazyLoadingList from 'components/common/lazyLoadingList/lazyLoadingList';
import InteractableDiv from 'components/common/interactableDiv';
import ClientCard from 'components/common/pwaCards/clientCard/clientCard';
import Arrow from 'components/common/icon/svg/arrow';
import ResponsiveModal from 'components/common/responsiveModal';
import ClientEditModal from 'components/common/clientEditModal';
import { ActionButtons } from 'components/common/responsiveModal/responsiveModal';
import styles from './selectCustomer.styles.pcss';

class SelectCustomer extends PureComponent {
  static propTypes = {
    client: PropTypes.shape({
      _id: PropTypes.string,
      clientId: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      removedOn: PropTypes.string,
    }),
    disabled: PropTypes.bool,
    onClientSelected: PropTypes.func.isRequired,
    className: PropTypes.string,
    country: PropTypes.string.isRequired,
    isRequired: PropTypes.bool,
    isSmallInlineSelection: PropTypes.bool,
    linkDisabled: PropTypes.bool,
    changeClientsFilter: PropTypes.func.isRequired,
    resetClientsState: PropTypes.func.isRequired,
    fetchClients: PropTypes.func.isRequired,
    clients: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalAmount: PropTypes.number.isRequired,
    isMobileSize: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    client: {},
    className: '',
    disabled: false,
    isRequired: true,
    isSmallInlineSelection: false,
    linkDisabled: false,
  };

  constructor(props) {
    super(props);

    this.searchInputRef = React.createRef();
    this.footerRef = React.createRef();

    this.state = {
      isModalClientsTableOpen: false,
      isClientAddOrEditModalOpen: false,
      searchText: '',
      isClientsFetchLoading: false,
      isClientsFetchError: false,
      sortBy: ['lastName', 'firstName'],
      sortDirection: 1,
      selectedClient: {},
      isClientsChangeFilterLoading: false,
      isClientsChangeFilterError: false,
      footerHeight: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedClient } = this.state;
    if (prevState.selectedClient?._id !== selectedClient?._id) {
      const footerHeight = this.footerRef?.current?.clientHeight;
      this.setState({ footerHeight });
    }
  }

  onSelectModalToggle = (isModalClientsTableOpen) => {
    this.setState({
      isModalClientsTableOpen,
    });
  };

  onRemoveClient = () => {
    const { onClientSelected, country } = this.props;

    onClientSelected({ country });
  };

  onSearchChange = async (value) => {
    this.setState({
      isClientChangeFilterLoading: true,
      searchText: value,
    });
  };

  newSearch = () => {
    if (this.searchInputRef.current) {
      this.searchInputRef.current.focusInput();
    }
  };

  getTableData = () => {
    return [
      {
        renderList: this.renderCustomersList(),
        title: polyglot.t('header.customers'),
        icon: <FontAwesomeIcon icon={faUsers} />,
        addActions: this.renderClientAddAction(),
      },
    ];
  };

  fetchClients = async () => {
    const {
      fetchClients,
      clients,
      totalAmount,
    } = this.props;
    const { sortBy, sortDirection, searchText } = this.state;

    if (clients.length >= totalAmount) {
      return;
    }

    this.setState({ isClientsFetchLoading: true });

    const fetchData = {
      skip: clients.length,
      sortBy,
      sortDirection,
      searchText,
    };

    try {
      await fetchClients(fetchData);
      this.setState({ isClientsFetchError: false });
    } catch (error) {
      this.setState({ isClientsFetchError: true });
    } finally {
      this.setState({ isClientsFetchLoading: false });
    }
  };

  changeClientsFilter = async (fetchOptions) => {
    const { changeClientsFilter } = this.props;
    this.setState({ isClientsChangeFilterLoading: true });

    try {
      await changeClientsFilter(fetchOptions);
      this.setState({ isClientsChangeFilterError: false });
    } catch (e) {
      this.setState({ isClientsChangeFilterError: true });
    } finally {
      this.setState({ isClientsChangeFilterLoading: false });
    }
  };

  handleClientAddOrEditModalSuccess = async (client) => {
    const { selectedClient } = this.state;
    const wasClientCreated = !!(!selectedClient?._id && client?._id);
    if (wasClientCreated) {
      this.onSubmit(client);
    }
    this.setState({ isClientAddOrEditModalOpen: false });
    if (client?._id) { this.setState({ selectedClient: client }); }
  };

  onSubmit = (client) => {
    const { onClientSelected } = this.props;
    const { selectedClient } = this.state;
    const clientToSubmit = client || selectedClient;

    onClientSelected({
      ...clientToSubmit,
      clientId: clientToSubmit._id,
    });
    this.onSelectModalToggle(false);
  };

  onRetryChangeFilter = async () => {
    const { sortBy, sortDirection } = this.state;
    this.setState({ isChangeFilterError: false });
    await this.changeClientsFilter({ sortBy, sortDirection });
  };

  onRetryFetch = async () => {
    this.setState({ isFetchError: false });
    await this.fetchClients();
  };

  renderClientAddAction = () => {
    return (
      <ButtonModern
        withIcon
        styleType={ButtonModern.STYLE.SELECT}
        onClick={() => this.setState({
          selectedClient: {},
          isClientAddOrEditModalOpen: true,
        })}
        size={ButtonModern.SIZE.SMALL}
        dataTest={'selectCustomerModalAddClientButton'}
      >
        <FontAwesomeIcon icon={faPlus} />
        {polyglot.t('confirm.client', 1)}
      </ButtonModern>
    );
  };

  renderClientRow = ({
    item, style,
  }) => {
    const client = item;
    const { isMobileSize } = this.props;
    const { selectedClient, isClientAddOrEditModalOpen } = this.state;
    const isSelected = selectedClient?._id === client._id;
    const actions = [
      {
        icon: <FontAwesomeIcon icon={faPen} />,
        action: () => this.setState({ isClientAddOrEditModalOpen: !isClientAddOrEditModalOpen }),
      },
      {
        icon: <FontAwesomeIcon icon={faXmark} />,
        action: () => this.setState({ selectedClient: {} }),
      },
    ];
    return (
      <InteractableDiv
        key={client._id}
        className={cx(styles.listRow, { [styles.listRowMobile]: isMobileSize })}
        onClick={() => this.setState({
          selectedClient: client,
        })}
        dataTest={`selectCustomerClientRow${client._id}`}
      >
        <ClientCard
          client={client}
          editClient={() => this.setState({
            isClientAddOrEditModalOpen: true,
          })}
          isSelected={isSelected}
          actions={isSelected ? actions : []}
        />
      </InteractableDiv>
    );
  };

  renderCustomersList = () => {
    const { clients, totalAmount, resetClientsState } = this.props;
    const {
      searchText,
      isClientsFetchLoading,
      isClientsChangeFilterLoading,
      sortDirection,
      sortBy,
      footerHeight,
    } = this.state;
    const hasMore
    = clients.length < totalAmount && totalAmount > 0;
    const isLoading = isClientsFetchLoading || isClientsChangeFilterLoading;
    return (
      <LazyLoadingList
        items={clients}
        fetchItems={this.fetchClients}
        renderRow={this.renderClientRow}
        searchText={searchText}
        changeFilter={this.changeClientsFilter}
        hasMore={hasMore || isLoading}
        sortDirection={sortDirection}
        sortBy={sortBy}
        offsetBottom={footerHeight || this.footerRef?.current?.clientHeight}
        emptyContent={!isLoading && this.renderEmptyContent()}
        notFoundContent={!isLoading && this.renderNotFoundContent()}
        resetState={resetClientsState}
      />
    );
  };

  renderFooterContent() {
    const { selectedClient } = this.state;

    return (
      <ActionButtons
        onClose={() => this.onSelectModalToggle(false)}
        onSubmit={() => this.onSubmit()}
        submitLabel={polyglot.t('actions.add')}
        hasSubmitButtonIcon
        withMargin
        hidePrimaryButton={!selectedClient?._id}
        wrapperRef={this.footerRef}
        dataTestSubmit={'selectCustomerModalSubmitButton'}
      />
    );
  }

  renderNotFoundContent = () => {
    const { isMobileSize } = this.props;
    return (
      <NoEntitiesModern
        withoutBorder
        className={styles.noEntities}
      >
        <div className={styles.noEntitiesSearchIcon}>
          <Icon type="search" />
        </div>
        <div className={cx(
          styles.noEntitiesHeading,
          { [styles.noEntitiesHeadingMobile]: isMobileSize },
        )}>
          {polyglot.t('clients.notFound')}
        </div>
        <div className={styles.noEntitiesButtons}>
          <ButtonModern
              className={styles.noEntitiesButtonsInner}
              styleType={ButtonModern.STYLE.SELECT}
              onClick={() => this.onSearchChange('')}
          >
            {polyglot.t('selectModal.notFound.showAll')}
          </ButtonModern>
          <ButtonModern
              className={styles.noEntitiesButtonsInner}
              styleType={'add'}
              onClick={this.newSearch}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span>
              {polyglot.t('invoices.actions.newSearch')}
            </span>
          </ButtonModern>
        </div>
      </NoEntitiesModern>
    );
  };

  renderEmptyContent = (type) => {
    const { isMobileSize } = this.props;
    return (
      <div className={styles.emptyContent}>
        {!isMobileSize && (
        <div className={styles.emptyContentIcon}>
          <Arrow />
        </div>
        )}
        <div>
          {polyglot.t('selectModal.notFound.client')}
        </div>
        <div>
          {polyglot.t('selectModal.notFound.add.client')}
        </div>
      </div>
    );
  };

  renderChooseCustomerButton = () => {
    const {
      client,
      isRequired,
      isSmallInlineSelection,
      linkDisabled,
      disabled,
      className,
    } = this.props;
    const isDisabled = client.removedOn || disabled;

    return (
      <div
        className={cx(
          styles.selectButton,
          {
            [styles.selectButtonNotAllowed]: isDisabled,
          },
          className,
        )}
      >
        <ButtonModern
          style={isSmallInlineSelection ? {
            height: '1.7em', margin: '0 0px', padding: '5px', fontSize: '9pt',
          } : {}}
          styleType="select"
          onClick={() => this.onSelectModalToggle(true)}
          dataTest="vehicleFormAddClient"
          disabled={isDisabled}
          className={{
            [styles.selectButtonDisabled]: isDisabled,
          }}
        >
          {client.clientId ? (
            <>
              {linkDisabled || isDisabled ? (
                <FontAwesomeIcon icon={faUser} />
              ) : (
                <Link to={`/clients/all/${client.clientId}`}>
                  <OpenIcon className={styles.selectButtonOpen} />
                </Link>
              )}
              <div data-test={`selectCustomerSelectedCustomer${client.lastName}`} className={styles.selectButtonText}>
                {`${client.lastName} ${client.firstName}`}
              </div>
              {!isDisabled && (
              <FontAwesomeIcon
                icon={faTimes}
                className={styles.selectButtonCancel}
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.onRemoveClient(event);
                }}
              />
              )}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faUser} />
              <div style={{ display: 'flex' }}>
                {polyglot.t('editVehicle.form.selectClient.selectButton')}
                <div style={{ color: 'red' }}>
                  {isRequired && ' *'}
                </div>
              </div>
            </>
          )}
        </ButtonModern>
      </div>
    );
  };

  render() {
    const {
      isMobileSize,
    } = this.props;
    const {
      isModalClientsTableOpen,
      searchText,
      isClientAddOrEditModalOpen,
      selectedClient,
    } = this.state;
    const tableData = this.getTableData();

    return (
      <>
        {this.renderChooseCustomerButton()}
        {isModalClientsTableOpen && (
          <ResponsiveModal
              isOpen={isModalClientsTableOpen}
              title={polyglot.t('editVehicle.clientsModal.title')}
              onClose={() => this.onSelectModalToggle(false)}
              width={tableData.length * 450}
              dataTest="selectCustomerModal"
              className={isMobileSize ? styles.modalMobile : styles.modal}
          >
            <GenericTableModal
              tableData={tableData}
              onSearchChange={this.onSearchChange}
              searchText={searchText}
              searchInputRef={this.searchInputRef}
              footerContent={this.renderFooterContent()}
            />
          </ResponsiveModal>
        )}
        {isClientAddOrEditModalOpen && (
          <ClientEditModal
            isOpen={isClientAddOrEditModalOpen}
            onClose={
              () => this.setState({ isClientAddOrEditModalOpen: !isClientAddOrEditModalOpen })
            }
            onSuccess={(client) => this.handleClientAddOrEditModalSuccess(client)}
            client={selectedClient}
            isRoutingDisabled={true}
          />
        )}
      </>
    );
  }
}

export default SelectCustomer;

const {
  PRODUCT_TYPES,
  isGroup,
  excludeFromVatCalculation,
} = require('../../definitions/productTypes');
const { getSpreadedInvoiceItems } = require('./helpers');

function roundDigits(value, digits) {
  let digitMultiplication = 10 ** digits;

  if (value < 0) {
    digitMultiplication *= -1;
  }

  return Math.round(Math.round(value * digitMultiplication * 100) / 100) / digitMultiplication;
}

function roundTwoDigitsCommercial(value) {
  return roundDigits(value, 2);
}

function taxRateToFaktor(rate) {
  return 1 + rate / 100;
}

function getTaxFromNet(price, taxRate, digits = 2) {
  if (!price || !taxRate) {
    return 0;
  }

  const priceFloat = roundDigits(parseFloat(price), 4);
  return roundDigits(priceFloat * (taxRate / 100), digits);
}

function getTaxFromGross(price, taxRate) {
  if (!price || !taxRate) {
    return 0;
  }

  const gross = roundDigits(parseFloat(price), 2);
  const net = roundDigits(gross / (1 + taxRate / 100), 2);
  return roundDigits(gross - net, 2);
}

function getNet(price, taxRate, digits = 2) {
  if (!price || !taxRate) {
    return price;
  }
  const priceFloat = parseFloat(price);
  return roundDigits(priceFloat / taxRateToFaktor(taxRate), digits);
}

function getGross(price, taxRate, digits = 2) {
  if (!price || !taxRate) {
    return price;
  }
  const priceFloat = parseFloat(price);
  return roundDigits(priceFloat * taxRateToFaktor(taxRate), digits);
}

function compensateRoundingTradeOff(tax, net, gross) {
  if (gross - (tax + net) > 0.001) {
    return tax + 0.01;
  }
  if (gross - (tax + net) < -0.001) {
    return tax - 0.01;
  }
  return tax;
}

function calculateItemPrice(price, isVATIncluded = false) {
  return isVATIncluded ? roundDigits(price, 4) : roundDigits(price, 2);
}

function calculateSumObject(itemsArray) {
  const result = {
    totalsByVatRate: [],
    totalsByVatCode: [],
    discount: 0,
  };

  itemsArray.forEach(({ index, positionPrice, quantity, unitPrice, price, vat, type, items }) => {
    if (index !== '*') {
      if (isGroup({ type })) {
        items.forEach((item) => {
          const displayNet = roundDigits(item.positionPrice, 2);
          const displayDiscount = roundDigits(
            item.quantity * (roundDigits(item.unitPrice, 2) - roundDigits(item.price, 2)),
            2,
          );

          result.discount += displayDiscount;
          if (!excludeFromVatCalculation(item)) {
            result.totalsByVatRate[item.vat.rate] = {
              amount:
                ((result.totalsByVatRate[item.vat.rate] &&
                  result.totalsByVatRate[item.vat.rate].amount) ||
                  0) + displayNet,
            };
            // we need this because some items do not have a vatRateId
            const vatRateId = item.vat._id || `${item.vat.rate}`;
            result.totalsByVatCode[vatRateId] = {
              amount:
                ((result.totalsByVatCode[vatRateId] && result.totalsByVatCode[vatRateId].amount) ||
                  0) + displayNet,
              contraAccount: item.vat.accountCode,
              percent: item.vat.rate,
              taxCode: item.vat.taxCode,
              name: item.vat.name,
            };
          }
        });
      } else {
        const displayNet =
          type === 'calculated' ? roundDigits(positionPrice, 4) : roundDigits(positionPrice, 2);
        const displayDiscount = roundDigits(
          quantity * (roundDigits(unitPrice, 2) - roundDigits(price, 2)),
          2,
        );

        result.discount += displayDiscount;
        if (!excludeFromVatCalculation({ type, positionPrice: displayNet })) {
          result.totalsByVatRate[vat.rate] = {
            amount:
              ((result.totalsByVatRate[vat.rate] && result.totalsByVatRate[vat.rate].amount) || 0) +
              displayNet,
          };
          // we need this because some items do not have a vatRateId
          const vatRateId = vat._id || `${vat.rate}`;
          result.totalsByVatCode[vatRateId] = {
            amount:
              ((result.totalsByVatCode[vatRateId] && result.totalsByVatCode[vatRateId].amount) ||
                0) + displayNet,
            contraAccount: vat.accountCode,
            percent: vat.rate,
            taxCode: vat.taxCode,
            name: vat.name,
          };
        }
      }
    }
  });
  return result;
}

function getTotalNetFromSumObjectArray(sumObjectArray) {
  let total = 0;
  Object.keys(sumObjectArray).forEach((vat) => {
    total += roundDigits(sumObjectArray[vat].amount, 4);
  });
  return roundTwoDigitsCommercial(total);
}

function getTotalTaxFromSumObjectArray(sumObjectArray, isVatIncluded) {
  let total = 0;
  if (isVatIncluded) {
    Object.keys(sumObjectArray).forEach((vat) => {
      total += getTaxFromGross(sumObjectArray[vat].amount, vat);
    });
  } else {
    Object.keys(sumObjectArray).forEach((vat) => {
      total += compensateRoundingTradeOff(
        getTaxFromNet(sumObjectArray[vat].amount, vat),
        roundDigits(sumObjectArray[vat].amount, 2),
        roundDigits(
          sumObjectArray[vat].amount + getTaxFromNet(sumObjectArray[vat].amount, vat, 4),
          2,
        ),
      );
    });
  }
  return roundTwoDigitsCommercial(total);
}

function getTaxSumsFromSumObjectArray(sumObjectArray, isVatIncluded) {
  if (isVatIncluded) {
    return Object.keys(sumObjectArray).map((vat) => ({
      rate: vat,
      sum: getTaxFromGross(sumObjectArray[vat].amount, vat),
      amount: roundTwoDigitsCommercial(
        sumObjectArray[vat].amount - getTaxFromGross(sumObjectArray[vat].amount, vat),
      ),
    }));
  }
  return Object.keys(sumObjectArray).map((vat) => ({
    rate: vat,
    sum: compensateRoundingTradeOff(
      getTaxFromNet(sumObjectArray[vat].amount, vat),
      roundDigits(sumObjectArray[vat].amount, 2),
      roundDigits(
        sumObjectArray[vat].amount + getTaxFromNet(sumObjectArray[vat].amount, vat, 4),
        2,
      ),
    ),
    amount: roundTwoDigitsCommercial(sumObjectArray[vat].amount),
  }));
}

function getGrossTaxSumsByIdMap(sumObjectArray) {
  return Object.keys(sumObjectArray).reduce((map, id) => {
    // eslint-disable-next-line no-param-reassign
    map[id] = {
      tax: getTaxFromGross(sumObjectArray[id].amount, sumObjectArray[id].percent),
      amount: roundTwoDigitsCommercial(sumObjectArray[id].amount),
      percent: sumObjectArray[id].percent,
      taxCode: sumObjectArray[id].taxCode,
      contraAccount: sumObjectArray[id].contraAccount,
      name: sumObjectArray[id].name,
    };
    return map;
  }, {});
}

function getNetTaxSumsByIdMap(sumObjectArray) {
  return Object.keys(sumObjectArray).reduce((map, id) => {
    // eslint-disable-next-line no-param-reassign
    map[id] = {
      tax: compensateRoundingTradeOff(
        getTaxFromNet(sumObjectArray[id].amount, sumObjectArray[id].percent),
        roundDigits(sumObjectArray[id].amount, 2),
        roundDigits(
          sumObjectArray[id].amount +
            getTaxFromNet(sumObjectArray[id].amount, sumObjectArray[id].percent, 4),
          2,
        ),
      ),
      amount: roundTwoDigitsCommercial(
        sumObjectArray[id].amount +
          getTaxFromNet(sumObjectArray[id].amount, sumObjectArray[id].percent, 4),
      ),
      percent: sumObjectArray[id].percent,
      taxCode: sumObjectArray[id].taxCode,
      contraAccount: sumObjectArray[id].contraAccount,
      name: sumObjectArray[id].name,
    };
    return map;
  }, {});
}

function getTaxSumsByIdSumByRate(sumObjectArray, rate) {
  const result = {
    sum: 0,
    lastId: '',
  };
  Object.keys(sumObjectArray).forEach((id) => {
    if (rate === sumObjectArray[id].percent) {
      result.sum += sumObjectArray[id].tax;
      result.lastId = id;
    }
  });
  return result;
}

function compensateDifferenceFromTaxSumsByIdSumToTaxSums(taxSumsById, taxSums, isVatIncluded) {
  taxSums.forEach((sum) => {
    const calculatedIdsSum = getTaxSumsByIdSumByRate(taxSumsById, Number(sum.rate));
    const difference = Number(sum.sum) - calculatedIdsSum.sum;
    if (calculatedIdsSum.lastId && difference !== 0) {
      // eslint-disable-next-line no-param-reassign
      taxSumsById[calculatedIdsSum.lastId].tax = roundTwoDigitsCommercial(
        taxSumsById[calculatedIdsSum.lastId].tax + difference,
      );
      if (!isVatIncluded) {
        // eslint-disable-next-line no-param-reassign
        taxSumsById[calculatedIdsSum.lastId].amount = roundTwoDigitsCommercial(
          taxSumsById[calculatedIdsSum.lastId].amount + difference,
        );
      }
    }
  });
  return taxSumsById;
}

function getTaxSumsByIdFromSumObjectArray(sumObjectArray, taxSums, isVatIncluded) {
  let result = isVatIncluded
    ? getGrossTaxSumsByIdMap(sumObjectArray)
    : getNetTaxSumsByIdMap(sumObjectArray);
  result = compensateDifferenceFromTaxSumsByIdSumToTaxSums(result, taxSums, isVatIncluded);
  return result;
}

function calculateTotals(itemsToCalculate, isVatIncluded = false) {
  const result = {
    total: 0,
    taxes: 0,
    gross: 0,
    discount: 0,
    taxSums: [],
  };

  const sumObject = calculateSumObject(itemsToCalculate);

  if (isVatIncluded) {
    result.gross = getTotalNetFromSumObjectArray(sumObject.totalsByVatRate);
    result.taxes = getTotalTaxFromSumObjectArray(sumObject.totalsByVatRate, isVatIncluded);
    result.total = roundTwoDigitsCommercial(result.gross - result.taxes);
  } else {
    result.total = getTotalNetFromSumObjectArray(sumObject.totalsByVatRate);
    result.taxes = getTotalTaxFromSumObjectArray(sumObject.totalsByVatRate, isVatIncluded);
    result.gross = roundTwoDigitsCommercial(result.total + result.taxes, isVatIncluded);
  }
  result.discount = roundTwoDigitsCommercial(sumObject.discount);
  result.taxSums = getTaxSumsFromSumObjectArray(sumObject.totalsByVatRate, isVatIncluded);
  result.taxSumsById = getTaxSumsByIdFromSumObjectArray(
    sumObject.totalsByVatCode,
    result.taxSums,
    isVatIncluded,
  );
  return result;
}

function calculateTotalsIncludingOpenAmount(itemsToCalculate, isVATIncluded = false) {
  const result = calculateTotals(itemsToCalculate, isVATIncluded);
  result.openAmount = result.gross;
  return result;
}

function calculateTotalsGroupedByProductType(items) {
  let productTotal = 0;
  let productPurchaseTotal = 0;
  let productWithPurchaseTotal = 0;
  let workTotal = 0;
  let workHoursTotal = 0;

  getSpreadedInvoiceItems(items).forEach((item) => {
    if (item.type === PRODUCT_TYPES.PRODUCT) {
      if (item.purchasePrice) {
        productWithPurchaseTotal += item.positionPrice;
        productPurchaseTotal += item.purchasePrice * item.quantity;
        productTotal += item.positionPrice;
      } else {
        productTotal += item.positionPrice;
      }
    }
    if (item.type === PRODUCT_TYPES.WORK) {
      workTotal += item.positionPrice;
      workHoursTotal += (item.workHours || 0) * item.quantity;
    }
  });

  return {
    productTotal,
    productPurchaseTotal,
    productWithPurchaseTotal,
    workTotal,
    workHoursTotal,
  };
}

function getGroupWithTotals(group, isVatIncluded) {
  const totals = calculateTotals(group.items, isVatIncluded);
  return {
    ...group,
    price: totals.total,
    positionPrice: totals.total,
    unitPrice: totals.total,
    tax: totals.taxes,
  };
}

module.exports = {
  roundDigits,
  roundTwoDigitsCommercial,
  getNet,
  getGross,
  calculateTotals,
  calculateTotalsIncludingOpenAmount,
  calculateTotalsGroupedByProductType,
  calculateItemPrice,
  getTaxFromGross,
  getTaxFromNet,
  getGroupWithTotals,
};

const {
  TRIAL_STATUSES,
  CREATION_FAILED,
  BASIS_PLAN,
  SMALL_BUSINESS_PLAN,
  ADDONS_WITHOUT_TRIAL,
} = require('../../definitions/subscription');

const hasBoughtApplication = (stripeObject) =>
  !!stripeObject?.subscriptions?.data[0] &&
  !stripeObject.subscriptions.data[0].cancel_at_period_end;

const hasTrialStatus = (garage) => TRIAL_STATUSES.includes(garage?.subscriptionData?.status);

const isInTrial = (garage) => hasTrialStatus(garage) && !hasBoughtApplication(garage?.stripeObject);

const isInBoughtTrial = (garage) =>
  hasTrialStatus(garage) && hasBoughtApplication(garage?.stripeObject);

function checkHasAddonTrial(subscriptionData, addon) {
  const { addonTrials } = subscriptionData;
  const now = new Date();
  return !!addonTrials.find(
    (trial) =>
      trial.addons.includes(addon) &&
      new Date(trial.startOn) <= now &&
      new Date(trial.endOn) >= now,
  );
}

function getHasAddon(subscriptionData, addon) {
  return (
    subscriptionData.status === CREATION_FAILED ||
    (TRIAL_STATUSES.includes(subscriptionData.status) && !ADDONS_WITHOUT_TRIAL.includes(addon)) ||
    !['', BASIS_PLAN, SMALL_BUSINESS_PLAN].includes(subscriptionData.planId) ||
    subscriptionData.addons[addon] ||
    checkHasAddonTrial(subscriptionData, addon)
  );
}

module.exports = {
  hasBoughtApplication,
  isInBoughtTrial,
  isInTrial,
  getHasAddon,
};

import fileSaver from 'file-saver';
import iconv from 'iconv-lite';
import csv from 'csvtojson';
import xml from 'xml2js';
import { promisify } from 'util';
import DeviceService from 'services/device.service';
import DownloadManager from 'helpers/downloadManager';
import polyglot from 'services/localization';
import toastService from 'helpers/toastService';

const JSZip = require('jszip');

const UNITS = ['kB', 'MB', 'GB'];

const FORBIDDEN_EXTENSIONS = [
  'action',
  'apk',
  'app',
  'asp',
  'aspx',
  'bat',
  'bin',
  'cab',
  'cgi',
  'cmd',
  'com',
  'command',
  'cpl',
  'csh',
  'css',
  'dmg',
  'ex_',
  'exe',
  'gadget',
  'inf1',
  'ins',
  'inx',
  'ipa',
  'isu',
  'job',
  'js',
  'jse',
  'jsp',
  'ksh',
  'lnk',
  'msc',
  'msi',
  'msp',
  'mst',
  'osx',
  'out',
  'paf',
  'php',
  'pif',
  'pl',
  'prg',
  'ps1',
  'reg',
  'rgs',
  'rhtml',
  'run',
  'scr',
  'sct',
  'shb',
  'shtml',
  'shs',
  'swf',
  'u3p',
  'vb',
  'vbe',
  'vbs',
  'vbscript',
  'workflow',
  'ws',
  'wsf',
  'wsh',
  'xhtml',
];

const parseString = promisify(xml.parseString);

export function formatFileSize(sizeInBytes) {
  const div = 1024;

  if (Math.abs(sizeInBytes) < div) {
    return `${sizeInBytes} B`;
  }

  let unitLevel = -1;

  do {
    // eslint-disable-next-line
    sizeInBytes /= div;
    unitLevel += 1;
  } while (Math.abs(sizeInBytes) >= div && unitLevel < UNITS.length - 1);

  return `${sizeInBytes.toFixed(1)} ${UNITS[unitLevel]}`;
}

export function showInBrowser(url) {
  const printLink = window.document.createElement('a');
  printLink.target = '__blank';
  printLink.rel = 'noopener noreferrer';
  printLink.href = url;
  printLink.click();
  printLink.remove();
}

export function printObjectUrl(url, name) {
  const printWindow = window.open('', '_blank');

  printWindow.document.write(`
      <html>
          <head>
              <title>${name}</title>
              <style>
                  body { margin: 0; display: flex; justify-content: center; align-items: center; height: 100vh; }
                  img { max-width: 100%; max-height: 100%; }
              </style>
          </head>
          <body>
              <img src="${url}" onload="window.print(); window.close();" />
          </body>
      </html>
  `);

  printWindow.document.close();
}

export function isPdf(fileName) {
  return !!fileName && fileName.substr(fileName.lastIndexOf('.') + 1) === 'pdf';
}

export function selectCorrectDocumentNumber(pdfType, document) {
  return document.number || document.costListingNumber || document.draftNumber || '';
}

export const getFileExtension = (fileName) => (fileName.includes('.') ? fileName.split('.').pop() : '');

export const isFileExtensionAllowed = (fileName = '') => {
  const fileExtension = getFileExtension(fileName);
  return !FORBIDDEN_EXTENSIONS.includes(fileExtension.toLowerCase());
};

const getContentTypeByExt = (ext) => {
  switch (ext) {
    case 'xlsx':
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'csv':
      return 'text/csv';
    case 'json':
      return 'application/json';
    default:
      return 'text/plain';
  }
};

export function downloadFile(data, filename = 'report.csv', charset = 'iso-8859-1') {
  const text = iconv.encode(data, charset);
  const fileExt = getFileExtension(filename);
  const contentType = getContentTypeByExt(fileExt);
  const blob = new Blob([text], { type: `${contentType};charset=${charset}` });
  fileSaver.saveAs(blob, filename);
}

export function downloadFileFromUrl(url, filename) {
  fileSaver.saveAs(url, filename);
}

export async function downloadFileFromUrlAsBlob(url, filename) {
  const blob = await fetchBlob(url);
  downloadFileFromBlob(blob, filename);
}

export async function fetchBlob(url) {
  const response = await fetch(url);
  return response.blob();
}

export function downloadFileFromBlob(payload, filename) {
  fileSaver.saveAs(payload, filename);
}

export function shouldShareFile() {
  return DeviceService.isMobile
  && [DeviceService.OS_LIST.I_OS, DeviceService.OS_LIST.MAC_OS].includes(DeviceService.OS)
  && navigator.share;
}

export async function downloadOrShareFileFromBlob(blob, fileName) {
  if (shouldShareFile()) {
    const data = {
      title: fileName,
      text: fileName,
      files: [
        new File([blob], fileName, {
          type: blob.type,
        }),
      ],
    };
    try {
      await navigator.share(data);
    } catch (e) {
      // try catch here for a reason.
      // navigator.share throws an error when share screen is cancelled
      // https://stackoverflow.com/questions/60510383/web-share-api-navigator-share-problem-when-canceling-share-dialog-ios-and-androi
    }
  } else {
    await downloadFileFromBlob(blob, fileName);
  }
}

export async function downloadZipFile(filename, urls, additionalFile) {
  const zip = new JSZip();
  const copiedUrls = [...urls];
  const DOWNLOAD_BATCH_SIZE = 100;
  const MAX_RETRIES = 10;
  let urlsToDownload = copiedUrls.splice(0, DOWNLOAD_BATCH_SIZE);
  const downloadId = DownloadManager.addDownload(0, urls.length);
  let blob;

  const downloadFileAsBlob = async (url, remainingRetries = MAX_RETRIES) => {
    let errMsg = '';
    try {
      const blobRes = await fetch(url);
      if (blobRes.status === 200) {
        DownloadManager.incrementProgress(downloadId, 1);
        DownloadManager.setDisplayText(downloadId, `${polyglot.t('reports.invoiceExportFile')} ${DownloadManager.getProgressByIdInPercent(downloadId)}%`);
        return blobRes.blob();
      }
    } catch (x) {
      errMsg = x.message;
    }
    await new Promise((resolve) => setTimeout(resolve, 200 * (MAX_RETRIES - remainingRetries)));
    if (!remainingRetries) throw new Error(errMsg);
    return downloadFileAsBlob(url, remainingRetries - 1);
  };

  while (urlsToDownload && urlsToDownload.length > 0) {
    for (let i = 0; i < urlsToDownload.length; i += 1) {
      const blobPromise = downloadFileAsBlob(urlsToDownload[i].url || urlsToDownload[i]);
      zip.file(`${urlsToDownload[i].title || i}.pdf`, blobPromise);
    }

    try {
      blob = await zip.generateAsync({ type: 'blob' });
    } catch (e) {
      DownloadManager.removeDownloadById(downloadId);
      toastService.showError(polyglot.t('reports.downloadFailed'));
      return;
    }
    urlsToDownload = copiedUrls.splice(0, DOWNLOAD_BATCH_SIZE);
  }

  if (additionalFile) {
    zip.file('invoices.csv', additionalFile);
    blob = await zip.generateAsync({ type: 'blob' });
  }

  toastService.showSuccess(polyglot.t('reports.downloadSuccess'));
  fileSaver.saveAs(blob, `${filename}.zip`);
  DownloadManager.removeDownloadById(downloadId);
}

export function getLinkedCsvBuilder(type) {
  switch (type) {
    case 'BMD':
      return buildLinkedBMDCSV;
    case 'RZL':
      return buildLinkedRzlCSV;
    default:
      return buildLinkedBMDCSV;
  }
}
function buildLinkedBMDCSV(csvString, invoiceList) {
  const lines = csvString.split('\n');
  let resString = '';
  let lineIdx = 0;
  for (let line of lines) {
    const fields = line.split(';');
    const invoiceToUse = invoiceList.find((el) => el.title.split(`${polyglot.t('reports.invoicePdfType')}_`)[1] === fields[3].replace(/"/g, '').replace(/\//g, '_'));
    if (lineIdx === 0) {
      line = `${line};"${polyglot.t('reports.document')}"`;
      resString += line;
    } else {
      line = `${line};"${invoiceToUse.title}.pdf"`;
      resString += `\n${line}`;
    }
    lineIdx += 1;
  }

  const text = iconv.encode(resString, 'iso-8859-1');
  const fileExt = getFileExtension('invoices.csv');
  const contentType = getContentTypeByExt(fileExt);
  return new Blob([text], { type: `${contentType};charset=iso-8859-1` });
}

function buildLinkedRzlCSV(csvString, invoiceList) {
  const lines = csvString.split('\n');
  let resString = '';
  for (const line of lines) {
    const fields = line.split(';');
    const invoiceToUse = invoiceList.find((el) => el.title.split('Rechnung_')[1] === fields[2].replace(/"/g, '').replace(/\//g, '_'));
    fields[36] = `"${invoiceToUse.title}.pdf"`;
    resString += `${fields.join(';')}\n`;
  }

  const text = iconv.encode(resString, 'iso-8859-1');
  const fileExt = getFileExtension('invoices.csv');
  const contentType = getContentTypeByExt(fileExt);
  return new Blob([text], { type: `${contentType};charset=iso-8859-1` });
}

export function readUploadedFileAsText(inputFile) {
  const tempFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    tempFileReader.onerror = () => {
      tempFileReader.abort();
      reject(tempFileReader.error);
    };

    tempFileReader.onload = () => {
      resolve(tempFileReader.result);
    };

    tempFileReader.readAsArrayBuffer(inputFile);
  });
}

export function parseCsvFile(string, options) {
  return csv(options).fromString(string);
}

export async function parseXmlFile(string) {
  return parseString(string);
}

export function getTireConfirmationPdf(id) {
  const { apiUrl, garage } = window.config;
  return `${apiUrl}/tires/${id}/pdf?garageId=${garage._id}`;
}

export function createFormDataWithAttachments(data, filesKeyName = 'attachments') {
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === filesKeyName) {
      data[key].files.forEach((attachment) => {
        formData.append('file', attachment);
      });
    } else {
      formData.append(key, data[key]);
    }
  });
  return formData;
}
